import bottleImg from '../assets/solo_product_w_background_1024@2x.jpg';

const PerfumeNotes = ({top, heart, base, perfumeName, madeBy}) => {
    return <>
        <p className="header-h3">Hello {madeBy},</p>
        <h1 className="header-h1">HERE'S YOUR PERSONAL SCENT!</h1>
        <div className="parfume-info">
            <div className="perfume-info-container">
                <div className="perfume-bottle-container">
                    <img src={bottleImg} alt="Nos Perfume" className="perfume-bottle-img"/>
                    <div className="perfume-bottle-name">
                        <p className="perfume-bottle-parfumeName">{perfumeName}</p>
                    </div>
                    <div className="perfume-bottle-by">
                        <p className="perfume-info-props">{madeBy}</p>
                    </div>
                </div>
                <div className="perfume-scentnotes-container">
                    <div className="perfume-scentnotes">
                        <p className="perfume-scentnotes-p">Top notes</p>
                        <p className="perfume-scentnotes-h3">{top}</p>
                    </div>
                    <div className="perfume-scentnotes">
                        <p className="perfume-scentnotes-p">Heart notes</p>
                        <p className="perfume-scentnotes-h3">{heart}</p>
                    </div>
                    <div className="perfume-scentnotes">
                        <p className="perfume-scentnotes-p">Base notes</p>
                        <p className="perfume-scentnotes-h3">{base}</p>
                    </div>
                </div>
            </div>
        </div>
    </>

};

export default PerfumeNotes;